import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AdminLayoutComponent } from './layouts/admin-layout-component'
import { AppComponent } from './app.component';
import { RequesterLayoutComponent } from './layouts/requester-layout-component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: AppComponent,
    canActivate:[MsalGuard]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    data: {
      title: 'Admin'
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/admin-module/admin.module').then(
            (m) => m.AdminModule
          ),
      },
    ]
  },
  {
    path: 'requester',
    component: RequesterLayoutComponent,
    data: {
      title: 'Requester'
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/requester-module/requester.module').then(
            (m) => m.RequesterModule
          ),
      },
    ]
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule { }
