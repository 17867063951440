import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  GlobalService,
  IUserInformation,
} from './core/services/global.service';
import { AppConst } from '../assets/shared.constant';
import { Router } from '@angular/router';
import { AccessRequestService } from './core/services/access-request.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  isCollapsed = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  accessToken: any;
  isAdmin: any;
  request: any = {
    scopes: [AppConst.scopesRead],
  };
  isLoading = true;
  shouldLoadModules = false;
  allowedToVisit: any = [];
  signedInUserEmail: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private globalService: GlobalService,
    private router: Router,
    private accessRequestService: AccessRequestService
  ) {}

  ngOnInit(): void {
    this.authService.initialize();

    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events.
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.HANDLE_REDIRECT_START
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((res: EventMessage) => {
        this.checkAndSetActiveAccount();
        const signedInUser: IUserInformation =
          this.globalService.getUserDetails();
        if (!signedInUser || signedInUser.email === '') {
          this.authService
            .acquireTokenSilent(this.request)
            .subscribe((tokenResponse) => {
              let url = this.router.url;
              let parts = url.split('/');
              let lastPart = parts[parts.length - 1];
              this.accessToken = tokenResponse.accessToken;
              this.globalService
                .getSingedinUserDetails(this.accessToken)
                .subscribe((response: any) => {
                  if (response) {
                    this.globalService.setUserDetails(
                      response as IUserInformation
                    );
                    this.isAdmin = response.isAdmin;
                    this.signedInUserEmail = response.email;

                    let pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                    let isRequestId = pattern.test(lastPart);

                    if (isRequestId) {
                      this.getNewRequestIdDetails(lastPart, this.accessToken);
                      // then call request api and navigate accordingly
                    } else {
                      // either go to new or dashboard
                      if (this.isAdmin) {
                        if (url.includes('/access-request/new')) {
                          this.router.navigateByUrl(
                            '/admin/access-request/new'
                          );
                        } else {
                          this.router.navigateByUrl('/admin');
                        }
                        this.isLoading = false;
                      } else {
                        if (url.includes('/access-request/new')) {
                          this.router.navigateByUrl(
                            '/requester/access-request/new'
                          );
                        } else {
                          this.router.navigateByUrl('/requester');
                        }
                      }
                    }
                    this.globalService.userType.next(this.isAdmin);
                    this.shouldLoadModules = true;
                  }
                });
            });
        } else {
          const signedInUser: IUserInformation =
            this.globalService.getUserDetails();
          if (signedInUser.isAdmin) {
            this.router.navigateByUrl('/admin');
          } else {
            this.router.navigateByUrl('/requester');
          }
          this.isLoading = false;
          this.shouldLoadModules = true;
        }
        this.isLoading = false;
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getNewRequestIdDetails(accessRequestId: string, accessToken: any) {
    let url = this.router.url;
    let parts = url.split('/');
    let lastPart = parts[parts.length - 1];
    this.accessRequestService
      .getSingleRequestData(accessRequestId, accessToken)
      .subscribe((response: any) => {
        let appOwnerEmail = response.appOwnerEmail;
        let technicalOwnerEmail = response.technicalOwnerEmail;
        let requesterEmail = response.requesterEmail;
        this.allowedToVisit.push(appOwnerEmail);
        this.allowedToVisit.push(technicalOwnerEmail);
        this.allowedToVisit.push(requesterEmail);

        response.approvals.forEach((item: any) => {
          let emails = item.approverEmail.split(',');
          emails.forEach((email: any) => {
            this.allowedToVisit.push(email.trim());
          });
        });

        if (this.isAdmin) {
          let isAvailable = this.allowedToVisit.includes(
            this.signedInUserEmail
          );

          if (isAvailable) {
            this.router.navigateByUrl('/admin/access-request/' + lastPart);
          } else {
            this.router.navigateByUrl('/admin');
          }
        } else {
          let isAvailable = this.allowedToVisit.includes(
            this.signedInUserEmail
          );

          if (isAvailable) {
            this.router.navigateByUrl('/requester/access-request/' + lastPart);
          } else {
            this.router.navigateByUrl('/requester');
          }
        }
      });
  }
}
