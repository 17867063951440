import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
@Injectable({ providedIn: 'root' })
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500) {
          this.notificationService.show(
            'error',
            'Something Went Wrong! Please try again.'
          );
        } else if (error.status == 401) {
          this.notificationService.show(
            'error',
            'Session expired. Please login again.'
          );
          const activeAccount =
            this.authService.instance.getActiveAccount() ||
            this.authService.instance.getAllAccounts()[0];

          if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
              account: activeAccount,
            });
          } else {
            this.authService.logoutRedirect({
              account: activeAccount,
            });
          }
        } else if (error.status == 400) {
          let errorMessage = error?.error?.message;
          this.notificationService.show('error', errorMessage);
        }
        return throwError(error);
      })
    );
  }
}
