import { Injectable } from '@angular/core';
import { AppConst } from '../../../assets/shared.constant';
@Injectable({
  providedIn: 'root',
})
export class ConfigService1 {
  baseURL: string = '';
  redirectURI: string = '';
  authority: string = '';
  clientId: string = '';
  scopesRead: string = '';
  constructor() {}

  load() {
    var request = new XMLHttpRequest();
    request.open('GET', '../assets/appSettings.svg', false); // request application settings synchronous
    request.send(null);
    const settings = JSON.parse(request.responseText);
    AppConst.authority = settings.authority;
    AppConst.baseURL = settings.baseURL;
    AppConst.clientId = settings.clientId;
    AppConst.scopesRead = settings.scopesRead;
    this.clientId = settings.clientId;
    sessionStorage.setItem('_x', JSON.stringify(settings));

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}
