<nz-layout class="app-layout">
  <app-header></app-header>
  <nz-layout>
    <nz-sider
      class="menu-sidebar menu-sidebar-color"
      nzCollapsible
      nzWidth="200px"
      nzBreakpoint="md"
      [nzCollapsedWidth]="50"
    >
      <ul
        nz-menu
        nzMode="inline"
        [nzInlineCollapsed]="isCollapsed"
        class="menu-sidebar-color"
      >
        <li nz-menu-item>
          <em nz-icon nzType="home"></em>
          <span
            ><a routerLink="/requester/dashboard" nzType="home" nzMatchRouters
              >Home</a
            ></span
          >
        </li>
        <li nz-menu-item>
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          <span
            ><a href="https://apis-catalog.atlascopco.com/developer/view-product/co-hr-data-hub-services-10008352" target="_blank"
              >HIP 2.0</a
            ></span
          >
        </li>
      </ul>
    </nz-sider>
    <nz-content style="overflow: auto">
      <div>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
