import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { AppConst } from '../../assets/shared.constant';
import {
  GlobalService,
  IUserInformation,
} from '../core/services/global.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './admin-layout-component.html',
  styleUrl: './admin-layout-component.scss',
})
export class AdminLayoutComponent implements OnInit {
  isCollapsed = false;
  isAdmin: any;

  constructor(private globalService: GlobalService) {}

  ngOnInit(): void {
    const signedInUser: IUserInformation = this.globalService.getUserDetails();
    if (!signedInUser || signedInUser.email !== '') {
      this.isAdmin = signedInUser.isAdmin;
    }
  }
}
